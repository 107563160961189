import React, { useEffect, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from 'components/common/Toast';
import { initializeTagManager } from 'services/externals/google/googleTagManager'; // Import TagManager
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { convertPrices } from 'services/coins/functions';
import { initMixpanel } from 'services/externals/mixpanel';
import AppContext from 'context/Context';
import { preloadBotAnimations } from 'services/lazyLoading/functions';
import { ChangePWAVersion } from 'services/pwa/components';

const Layout = () => {
  const {
    config: { currency },
    setRefCoinValues,
    setRefCoinPerc24h
  } = useContext(AppContext);
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  const getCoins = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'coinPrices',
      httpMethod: 'get',
      usingCredentials: true,
      pathParams: {
        exchange: 'binance'
      },
      showError: false
    });
    if (resp.validResponse) {
      const { prices, perc_24h } = convertPrices(resp.data, currency);
      setRefCoinValues(prices);
      setRefCoinPerc24h(perc_24h);
    }
  };

  useEffect(async () => {
    // GTM Initialization
    initializeTagManager();
    // Mixpanel Initialization
    initMixpanel();
    // Coin data management
    // TODO: wait only in dashboard and botDetails
    await getCoins();

    // Preload animations
    preloadBotAnimations();

    // Browser detection and class addition
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    // TODO: do it only in dashboard and botDetails
    var intervalBinanceData = setInterval(async () => {
      await getCoins();
    }, 10000);

    return () => {
      clearInterval(intervalBinanceData);
    };
  }, []);

  return (
    <>
      {/* check if user must update pwa app */}
      <ChangePWAVersion />
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <Route component={MainLayout} />
        <Redirect to="/errors/404" />
      </Switch>
      <SettingsPanel />
      <ToastContainer
        transition={Fade}
        closeButton={CloseButton}
        closeOnClick
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
