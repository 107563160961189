import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { MainLayout as textLang } from 'staticData/languages';
import { breakpoints } from 'helpers/utils';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveToLocalStorage } from 'staticData/common';
import { useHistory } from 'react-router-dom';
import { trackEvent } from 'services/externals/mixpanel';

const Header = ({ setShowHeader }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [title, setTitle] = useState();
  const routerHistory = useHistory();

  const handleClick = () => {
    trackEvent('Referral Header Click', {
      mail: userInfos.email,
      type: 'click',
      title: title
    });
    routerHistory.push('/rewards');
  };

  const handleClose = () => {
    trackEvent('Referral Header Click', {
      mail: userInfos.email,
      type: 'close',
      title: title
    });
    setShowHeader(false);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 3);
    saveToLocalStorage('hideHeaderUntill', tomorrow);
  };

  useEffect(() => {
    const loadTitle = () => {
      const titlesArray = [textLang.headerText, textLang.headerText_1];
      setTitle(titlesArray[Math.floor(Math.random() * titlesArray.length)]);
    };

    loadTitle();
  }, []);

  return (
    <>
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1020
        }}
      >
        <Row
          className="py-2 pointerCursor"
          style={{
            backgroundColor: '#2425f1',
            minHeight: '2.5rem',
            '--falcon-gutter-x': 0,
            position: 'relative'
          }}
          onClick={handleClick}
        >
          <Col as={Flex} justifyContent="center" alignItems="center">
            <p
              className="mb-0 text-dark"
              style={
                window.innerWidth < breakpoints['sm']
                  ? { fontSize: '.75rem' }
                  : {}
              }
            >
              <img
                draggable="false"
                role="img"
                className="emoji"
                alt="🥳"
                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f973.svg"
                width="15"
              />{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: title ? title[lang] : ''
                }}
              />
            </p>
          </Col>
        </Row>
        <div
          className="text-dark pointerCursor"
          style={{
            position: 'absolute',
            right: '15px',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
          onClick={handleClose}
        >
          <FontAwesomeIcon icon="times" />
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  setShowHeader: PropTypes.func.isRequired
};

export default Header;
