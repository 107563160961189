import React, { useContext } from 'react';
import { Row, Nav, Card, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PersonalInfo from './PersonalInfo';
import Subscription from './Subscription';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { cleanCache } from 'services/cache';
import { googleLogout } from '@react-oauth/google';
import { resetUser } from 'services/externals/mixpanel';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Profile as textLang } from 'staticData/languages';
import { version } from 'config';

const Index = ({ getInfo }) => {
  const {
    config: { lang },
    setUserInfos,
    setLoggedIn
  } = useContext(AppContext);
  const routerHistory = useHistory();

  const handleLogout = async () => {
    await googleLogout();
    let logoutResponse = await endpointInterface(
      lang,
      'backend',
      'logoutPage',
      'post',
      true
    );
    if (logoutResponse.validResponse) {
      await cleanCache(0);
      setLoggedIn(false);
      setUserInfos({});
      resetUser();
      routerHistory.push('/login');
    } else {
      toast.error(logoutResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Card className="pe-0">
            <Card.Header>
              <h4 className="text-800 mb-0 me-2">Account</h4>
            </Card.Header>
          </Card>
        </Col>
      </Row>
      <PersonalInfo getInfo={getInfo} />
      <Subscription getInfo={getInfo} />
      <Row className="mb-3">
        <Col>
          <Card className="pe-0">
            <Card.Header>
              <Nav.Link
                className="px-2 theme-control-toggle"
                onClick={() => handleLogout()}
              >
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="ThemeColor">{textLang.logout[lang]}</Tooltip>
                  }
                >
                  <Row
                    className="ps-2 custom-link-color"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Col sm={1} style={{ width: '3.333333%' }}>
                      <FontAwesomeIcon icon="power-off" className="fs-0" />
                    </Col>
                    <Col style={{ maxWidth: 'fit-content' }}>
                      <b className="text-danger">Logout</b>
                    </Col>
                  </Row>
                </OverlayTrigger>
              </Nav.Link>
            </Card.Header>
          </Card>
        </Col>
      </Row>
      {/* show version only in mobile */}
      <Row className="pt-2 d-sm-none">
        <Col sm={6} className="text-end">
          <small>{version}</small>
        </Col>
      </Row>
    </>
  );
};

Index.propTypes = {
  getInfo: PropTypes.function
};

export default Index;
