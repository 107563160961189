import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Profile as textLang,
  monthsList,
  languagesString
} from 'staticData/languages';
import LanguageDropdown from 'components/navbar/top/LanguageDropdown';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import DoKYC from 'pages/common/modals/DoKYC';
import AddKeys from 'pages/common/modals/AddKeys';
import { trackEvent } from 'services/externals/mixpanel';
import { capitalize } from 'helpers/utils';
import { useHistory } from 'react-router-dom';

const PersonalInfo = ({ getInfo }) => {
  const {
    config: { lang },
    userInfos,
    setUserInfos
  } = useContext(AppContext);
  var defaultTooltipText = textLang.copy[lang];
  const userDate = new Date(userInfos.created_at * 1000);
  const createdAt =
    lang === 'it-IT'
      ? String(userDate.getDate()) +
        ' ' +
        monthsList.months[lang][userDate.getMonth()] +
        ' ' +
        String(userDate.getFullYear())
      : monthsList.months[lang][userDate.getMonth()] +
        ' ' +
        userDate.getDate() +
        ' ' +
        userDate.getFullYear();
  const referral = userInfos.my_referral;
  const walletConnected = userInfos.connected_exchanges?.includes('binance');
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);
  const [ConfirmMailShow, setConfirmMailShow] = useState(false);
  const [doKYCShow, setDoKYCShow] = useState(false);
  const [addKeysShow, setAddKeysShow] = useState(false);
  const [fastAPiError, setFastApiError] = useState('');
  const queryParameters = new URLSearchParams(window.location.search);
  const fastApiConnected = queryParameters.get('fast_api') === 'connected';
  const error_id = queryParameters.get('error_id');
  const kyc_accepted_status = ['approved', 'contact_support', 'processing'];
  const routerHistory = useHistory();

  const getStep = () => {
    var returnValue;
    if (!userInfos.email_confirmed) returnValue = 0;
    else if (!kyc_accepted_status.includes(userInfos.kyc_status))
      returnValue = 1;
    else if (!userInfos.connected_exchanges.includes('binance'))
      returnValue = 2;
    else returnValue = 3;
    return returnValue;
  };
  const [step, setStep] = useState(getStep());

  const walletMapData = {
    Binance: {
      guideLink: '/connect-binance-wallet',
      depositGuide: '/deposit-binance',
      convertGuide: '/convert-on-binance',
      available: true,
      userCanContact: true
    }
  };

  const handleSetKYCStatus = kycStatus => {
    setUserInfos({ ...userInfos, kyc_status: kycStatus });
  };

  const copyReferral = async () => {
    navigator.clipboard.writeText(referral);
    setTooltipText(textLang.copied[lang]);
    setTimeout(() => {
      setTooltipText(defaultTooltipText);
    }, 1500);
  };

  useEffect(() => {
    // track event fast API
    if (fastApiConnected || error_id) {
      trackEvent('exchangeKeys', {
        status_event: fastApiConnected ? 'add' : 'error',
        type: 'fastApi',
        error_id: error_id,
        page: window.location.pathname
      });
      if (error_id) setFastApiError(error_id);
      setAddKeysShow(true);
      queryParameters.delete('fast_api');
      routerHistory.push(`?${queryParameters.toString()}`);
    }
  }, []);

  return (
    <>
      <ConfirmMail
        ConfirmMailShow={ConfirmMailShow}
        setConfirmMailShow={setConfirmMailShow}
        step={0}
        withHeader={false}
        userCanClose={true}
      />
      <DoKYC
        doKYCShow={doKYCShow}
        setDoKYCShow={setDoKYCShow}
        step={step}
        setStep={setStep}
        setAddKeysShow={setAddKeysShow}
        setKycStatus={handleSetKYCStatus}
      />
      <AddKeys
        addKeysShow={addKeysShow}
        setAddKeysShow={setAddKeysShow}
        exchange={'Binance'}
        walletMapData={walletMapData}
        realoadUserInfo={getInfo}
        step={step}
        setStep={setStep}
        error_id={fastAPiError}
        connected={fastApiConnected}
      />
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header>
              <h4>{textLang.personalInfo[lang]}</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="user" className="me-2" />
                      <h6>{textLang.nameAndSurname[lang]}</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>
                        {userInfos.kyc?.name
                          ? capitalize(userInfos.kyc?.name)
                          : '----'}{' '}
                        {userInfos.kyc?.surname
                          ? capitalize(userInfos.kyc?.surname)
                          : '----'}
                      </h5>
                      {!kyc_accepted_status.includes(userInfos.kyc_status) && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={
                            <Tooltip id="kyc-tooltip">
                              {textLang.kycText[lang]}
                            </Tooltip>
                          }
                        >
                          <div style={{ width: 'fit-content' }}>
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-default"
                              icon="user"
                              onClick={() =>
                                step === 1
                                  ? setDoKYCShow(true)
                                  : setConfirmMailShow(true)
                              }
                              iconAlign="right"
                            >
                              {textLang.kyc[lang]}
                            </IconButton>
                          </div>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="at" className="me-2" />
                      <h6>{textLang.mailAddress[lang]}</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>{userInfos.email}</h5>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="changeusername">
                            {userInfos.email_confirmed
                              ? textLang.mailVerified[lang]
                              : textLang.mailNotVerified[lang]}
                          </Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <IconButton
                            style={{
                              padding: '0 0.35rem',
                              cursor: userInfos.email_confirmed
                                ? 'default'
                                : 'pointer'
                            }}
                            variant={
                              userInfos.email_confirmed
                                ? 'falcon-success'
                                : 'falcon-danger'
                            }
                            icon={
                              userInfos.email_confirmed
                                ? 'check-circle'
                                : 'times'
                            }
                            onClick={() =>
                              userInfos.email_confirmed
                                ? null
                                : setConfirmMailShow(true)
                            }
                            iconAlign="right"
                          >
                            <span className="d-none d-sm-inline-block">
                              {userInfos.email_confirmed
                                ? textLang.confirmed[lang]
                                : textLang.notConfirmed[lang]}
                            </span>
                          </IconButton>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="user" className="me-2" />
                      <h6>Username</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>{userInfos.username}</h5>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="changeusername">
                            {textLang.changeUsername[lang]}
                          </Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <Link to="/change-username">
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-default"
                              icon="edit"
                              iconAlign="right"
                            >
                              {textLang.edit[lang]}
                            </IconButton>
                          </Link>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="key" className="me-2" />
                      <h6>Password</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>************</h5>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="changepwd">
                            {textLang.changePassword[lang]}
                          </Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <Link to="/change-password">
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-default"
                              icon="edit"
                              iconAlign="right"
                            >
                              {textLang.edit[lang]}
                            </IconButton>
                          </Link>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row className="my-1 d-sm-none">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="flag" className="me-2" />
                      <h6>{textLang.language[lang]}</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>{languagesString[lang]}</h5>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="wallet" className="me-2" />
                      <h6>Binance</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <SoftBadge
                        bg={walletConnected ? 'success' : 'danger'}
                        className="d-flex align-items-center"
                      >
                        {walletConnected
                          ? textLang.connected[lang]
                          : textLang.notConnected[lang]}
                      </SoftBadge>
                      {!walletConnected && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={
                            <Tooltip id="connectWallet">
                              {textLang.connectBinance[lang]}
                            </Tooltip>
                          }
                        >
                          <div style={{ width: 'fit-content' }}>
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-success"
                              icon="link"
                              onClick={() =>
                                step === 2
                                  ? setAddKeysShow(true)
                                  : step === 1
                                  ? setDoKYCShow(true)
                                  : setConfirmMailShow(true)
                              }
                              iconAlign="right"
                            >
                              {textLang.connect[lang]}
                            </IconButton>
                          </div>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="gift" className="me-2" />
                      <h6>Referral Code</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>{referral}</h5>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="copyReferral">{tooltipText}</Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <IconButton
                            style={{ padding: '0 0.35rem' }}
                            variant="falcon-default"
                            icon="clone"
                            onClick={copyReferral}
                            iconAlign="right"
                          >
                            {textLang.copyButton[lang]}
                          </IconButton>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="calendar" className="me-2" />
                      <h6>{textLang.createdAt[lang]}</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>{createdAt}</h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

PersonalInfo.propTypes = {
  getInfo: PropTypes.func.isRequired
};

export default PersonalInfo;
