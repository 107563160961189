import React, { useState, useContext, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import WizardForm from './wizard/Wizard';
import KYC from './KYC';
import FormTitle from 'pages/common/FormTitle';
import { TraderProcedure as textLang } from 'staticData/languages';
import { traderProTier } from 'staticData/common';
import { useLocation, useHistory } from 'react-router-dom';
import { trackEvent } from 'services/externals/mixpanel';
import AppContext from 'context/Context';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Document from './Document';
import Payment from './Payment';
import Done from './Done';
import Flex from 'components/common/Flex';

const getCurrentPage = userInfos => {
  const { kyc_status, trader, pro_tier } = userInfos;
  if (
    kyc_status !== 'approved' &&
    kyc_status !== 'contact_support' &&
    kyc_status !== 'processing'
  )
    return 0;
  if (!trader?.contract_signed_at && !trader?.tmp_contract_signed) return 1;
  if (pro_tier < traderProTier) return 2;
  return 3;
};

const TraderProcedure = () => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [ConfirmMailShow, setConfirmMailShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(getCurrentPage(userInfos));
  const routerHistory = useHistory();
  // get params in url
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const subscription = queryParams.get('subscription');
  // fixed from docusign url
  const docusignEvent = queryParams.get('event');

  const documentSigned = async () => {
    const resp = await endpointInterface(
      lang,
      'backend',
      'docusignCompleted',
      'post',
      true
    );
    if (resp.responseStatus !== 200) {
      console.error('Error occurred while inserting document');
      return;
    }
    setPage(2);
    setLoading(false);
  };

  useEffect(async () => {
    if (!userInfos.email_confirmed) setConfirmMailShow(true);
    // stripe event success
    if (subscription === 'success') {
      trackEvent('subscription', {
        subscription_type: 'Private'
      });
      const newQueryParams = new URLSearchParams(window.location.search);
      newQueryParams.set('subscription', 'accepted');
      routerHistory.replace({
        pathname: window.location.pathname,
        search: newQueryParams.toString()
      });
    }
    // docusign event success
    if (docusignEvent === 'signing_complete') {
      setLoading(true);
      trackEvent('docusign', {
        type: 'new_page'
      });
      const newQueryParams = new URLSearchParams(window.location.search);
      newQueryParams.delete('event');
      newQueryParams.set('docusign_status', 'accepted');
      routerHistory.replace({
        pathname: window.location.pathname,
        search: newQueryParams.toString()
      });
      // send data to backend
      await documentSigned();
    }
  }, []);

  return (
    <>
      <ConfirmMail
        ConfirmMailShow={ConfirmMailShow}
        setConfirmMailShow={setConfirmMailShow}
        step={0}
        withHeader={false}
        userCanClose={false}
      />
      <Row>
        <FormTitle
          icon="spinner"
          title={textLang.title[lang]}
          subtitle={textLang.subtitle[lang]}
        />
      </Row>
      <Row className="g-3">
        <Col xxl={12} className="mt-0 h-100">
          <WizardForm step={page} />
        </Col>
      </Row>
      {!loading ? (
        <>
          {page === 0 && <KYC setStep={setPage} step={page} />}
          {page === 1 && <Document setStep={setPage} newStep={2} />}
          {page === 2 && <Payment />}
          {page === 3 && <Done />}
        </>
      ) : (
        <Row className="my-5">
          <Col as={Flex} className="justify-content-center">
            <Spinner />
          </Col>
        </Row>
      )}
    </>
  );
};

export default TraderProcedure;
